* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border-collapse: collapse;
}

.main {
  width: 100vw;
  height: 100dvh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}